<template>
    <div>
        <SearchForm @onReset="resetData" @onSearch="filterSearch" />
        <div class="shadow-lg rounded-lg pt-3 pb-5 bg-white mt-10 relative">
            <LoadingComponent :isLoading="loading" />
            <div class="overflow-x-auto">
                <table class="myTable">
                    <thead>
                        <tr>
                            <th>暱稱</th>
                            <th>真實姓名</th>
                            <th>手機</th>
                            <th class="w-[100px] min-w-[100px] cursor-pointer" @click="rateSorted">
                                <span>評分</span>
                                <div class="w-[20px] inline-block">
                                    <!-- 未點擊前出現 往上箭頭icon -->
                                    <i v-if="isHighestRateSorted === null" class="icofont-long-arrow-up mr-1 text-lg text-gray-800"></i>
                                    <!-- 未點擊前出現 往下箭頭icon -->
                                    <i v-if="isHighestRateSorted === null" class="icofont-long-arrow-down text-lg py-1 text-gray-800 -ml-3"></i>
                                    <i
                                        v-if="isHighestRateSorted && isHighestRateSorted !== null"
                                        class="icofont-long-arrow-up text-orange-600 mr-1 text-lg py-1"></i>
                                    <i
                                        v-if="!isHighestRateSorted && isHighestRateSorted !== null"
                                        class="icofont-long-arrow-down text-orange-600 text-lg py-1"></i>
                                </div>
                            </th>
                            <th>帳號</th>
                            <th>註冊日期</th>
                            <th v-permission="['update']">編輯</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                            <td>{{ $subString(item.name, 10) }}</td>
                            <td>
                                {{ !$isEmpty(item.real_name) ? $subString(item.real_name, 10) : item.real_name }}
                            </td>
                            <td>{{ item.phone }}</td>
                            <td>{{ item.rating_score }}</td>
                            <td :class="item.status === 0 ? 'text-black' : 'text-orange-600'">
                                {{ item.status | showStatusText }}
                            </td>
                            <td>
                                {{ $moment(item.created_at).format("YYYY/MM/DD") }}
                            </td>
                            <td v-permission="['update']" class="cursor-pointer" @click="goToEdit(item.id)">
                                <i class="far fa-edit"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="justify-center mt-10 flex">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
// 搜尋表單
import SearchForm from "./SearchForm.vue";
// 分頁組件
import MyPagination from "../../../components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "../../../components/Loading.vue";
export default {
    name: "MemberList",
    components: {
        SearchForm,
        MyPagination,
        LoadingComponent,
    },
    filters: {
        showStatusText(val) {
            switch (val) {
                case 0:
                    return "使用中";
                case 1:
                    return "使用中";
                case 2:
                    return "使用中";
                case -1:
                    return "停權";
                case -2:
                    return "永久停權";
                default:
                    return val;
            }
        },
    },
    data() {
        return {
            // 預設頁面
            currentPage: 0,
            // 分頁資料
            paginationData: {
                limit: 15,
                total: 0,
            },
            // 搜尋條件
            filterData: {},
            // 資料
            datas: [],
            // 旋轉動畫
            loading: false,
            // 判斷是否選擇評分排序
            isHighestRateSorted: null,
        };
    },
    methods: {
        /**
         * @param { tpye Object(物件) } filterData 搜尋過濾資料
         */
        filterSearch(filterData) {
            this.filterData = filterData;
            this.getLists({
                ...this.filterData,
                page: 1,
                limit: this.paginationData.limit,
            });
        },
        /**
         * 更新一頁取得幾筆資料
         * @param { type Number(數字) } val 一頁取得幾筆資料設定值
         */
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
            });
        },
        /**
         * 切換分頁
         * @param { type Number(數字) } val 分頁頁碼
         */
        currentPageChange(val) {
            // 設定目前分頁為第幾幾面
            this.currentPage = val;
            // 清空列表資料
            this.datas = [];
            this.getLists({
                page: this.currentPage,
                limit: this.paginationData.limit,
                ...this.filterData,
            });
        },
        /**
         * 取得列表資料
         * @param { type Object(物件) } filterData 搜尋條件
         * @param { type Boolean(布林) } isDefaultSorted 判斷是否用預設排序
         */
        async getLists(filterData) {
            this.loading = true;
            // 判斷沒有傳入 sort_type 值時 預設為倒序
            if (!Object.prototype.hasOwnProperty.call(filterData, "sort_type")) {
                filterData["sort_type"] = "desc";
            }
            try {
                const { data } = await this.$api.GetMemberListApi(filterData);
                this.loading = false;
                this.currentPage = data.current_page;
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
                this.datas = data.data;
            } catch (err) {
                this.loading = false;
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            }
        },
        // 清空選擇條件
        resetData() {
            this.filterData = {};
            this.currentPage = 1;
            this.isHighestRateSorted = null;
            this.getLists({ limit: this.paginationData.limit });
        },
        /**
         * 跳轉編輯頁
         * @param { type String(字串) } id
         */
        goToEdit(id) {
            this.$router.push({ name: "member_update", params: { id } });
        },
        // 點擊評分排序
        rateSorted() {
            // 判斷值為 false 時 執行重置
            if (this.isHighestRateSorted === false) {
                this.isHighestRateSorted = null;
                // 刪除排序方式
                this.$delete(this.filterData, "sort_type");
                // 刪除排序規則
                this.$delete(this.filterData, "sort_by");
                this.getLists({
                    limit: this.paginationData.limit,
                    page: this.currentPage,
                    ...this.filterData,
                });
                return;
            }
            // 更換排序規則
            this.isHighestRateSorted = !this.isHighestRateSorted;
            // 新增排序規則
            this.$set(this.filterData, "sort_type", this.isHighestRateSorted ? "desc" : "asc");
            // 新增排序方式
            this.$set(this.filterData, "sort_by", "rating_score");
            // 帶入 評分排序參數
            this.getLists({
                limit: this.paginationData.limit,
                page: this.currentPage,
                ...this.filterData,
            });
            // this.isHighestRateSorted = !this.isHighestRateSorted;
            // // 判斷排序方式是由高到底還是低到高
            // const sortType = this.isHighestRateSorted ? "desc" : "asc";
            // // 帶入 評分排序參數
            // this.getLists({ sort_by: "rating_score", sort_type: sortType });
        },
    },
    created() {
        this.getLists({ limit: this.paginationData.limit });
    },
    activated() {
        this.getLists({ limit: this.paginationData.limit, ...this.filterData });
    },
};
</script>
